import $ from 'jquery';
import Typed from 'typed.js';
import Swiper from 'swiper/bundle';

declare let global: any;
Object.assign(window, { $: jQuery, jQuery });

$(function() {

    // ======= Menu toggle ======= //
    $('.menu-open').on("click", function() {
        $(this).addClass('opened');
        $('.main-navigation-wrapper').addClass('opened');
        $('body').addClass('stop-scroll');
    });
    $('.menu-close').on("click", function() {
        $(this).removeClass('opened');
        $('.main-navigation-wrapper').removeClass('opened');
        $('body').removeClass('stop-scroll');
    });

    $('body').on('click', 'a[href*="#"]', function(e){
        var fixed_offset = 100;
        if ($(this).attr('href') !== '#') {
            $('html,body').stop().animate({ scrollTop: $(this.hash).offset().top - fixed_offset }, 1000);
        }
        e.preventDefault();
    });

    $('.button.disactive').on("click", function(e) {
        e.preventDefault();
    });

    // ======= Alert close ======= //

    $('body').on('click', '.alert .close', function() {
        $(this).parents('.message-wrapper').slideUp(500);
    });

    // ======= Custom select ======= //

    (function() {
        $('.select-list').each(function() {
            $(this).slideUp(0);
        });
        $('.select-item .input-wrapper').on("click", function(e) {
            var parent = $(this),
            select_list = parent.children('.select-list');
            if ($(this).hasClass('categories')) {
                select_list = parent.children('.cat-select-list');
            }
            if (parent.hasClass('opened')) {
                select_list.slideUp(500);
                parent.removeClass('opened');
            } else {
                select_list.slideDown(500);
                parent.addClass('opened');
            }
        });
        $(document).on("mouseup", function (e){
            var div = $(".select-item");
            if (!div.is(e.target)
                && div.has(e.target).length === 0) {
                div.find('.select-list').slideUp(500);
                div.children('.input-wrapper').removeClass('opened');
            }
        });
        $(document).on("mouseup", function (e){
            var div = $(".select-item");
            if (!div.is(e.target)
                && div.has(e.target).length === 0) {
                div.find('.cat-select-list').slideUp(500);
                div.children('.input-wrapper').removeClass('opened');
            }
        });
        $('.select-option').on("click", function() {
            var parent = $(this).parents('.input-wrapper'),
                form_select = parent.children('.form-select'),
                value = $(this).text(),
                data_value = $(this).data('value');
            if (data_value) {
                form_select.val(data_value).attr('data-focus', 'true');
            } else {
                form_select.val(value).attr('data-focus', 'true');
            }
        });
        $('.cat-select-option').on("click", function(e) {
            var parent = $(this).parents('.input-wrapper'),
                form_select = parent.children('.form-select'),
                value = $(this).text(),
                data_value = $(this).data('value');
            if (data_value) {
                form_select.text(data_value).attr('data-focus', 'true');
            } else {
                form_select.text(value).attr('data-focus', 'true');
            }
            
        });
        $('.country-select-option').on('click', function() {
            var abv = $(this).data('country-abv'),
                country = $(this).text(),
                parent = $(this).parents('.input-wrapper'),
                input = parent.children('.form-input');

            input.data('default-val', country);
            $('#country_abv').val(abv);
        });
    
        $('.signup-form #country').on('focus', function() {
            $(this).val('');
        });
        
        $('.signup-form #country').on('blur', function() {
            var this_elem = $(this);
            $(this).val($(this).data('default-val'));
            setTimeout(function() {
                this_elem.parent().find('.select-option').each(function() {
                    $(this).removeClass('hide');
                });
            }, 1000);
        });
    })();

    // ======= Validation form ======= //
    (function() {
        $('.form-input[required]').each(function() {
            var value = $(this).val();
            
            if (value.length) {
                $(this).attr('data-focus', 'true');
            }
        });
        $('.form-input[required]').each(function() {
            var value = $(this).val();
            if (value.length) {
                $(this).attr('data-focus', 'true');
            }
        });
    })();

    $('.form-input[required]').on('input', function() {
        if ($(this).parents('form').hasClass('login-form')) {
            return;
        }
        var data_focus = $(this).attr('data-focus');
        if (data_focus === 'false') {
            $(this).attr('data-focus', 'true');
        }
        $('.form-input[required]').each(function() {
            var data_focus = $(this).attr('data-focus');
            
            if (data_focus === 'true') {
                if ($(this).attr('type') === 'checkbox') {
                    var checked = $(this).prop('checked');
                    
                    if (!checked) {
                        $(this).parents('.checkbox-item').addClass('error');
                    } else {
                        $(this).parents('.checkbox-item').removeClass('error');
                    }
                } else {
                    var value = $(this).val();
                    if (value === '') {
                        $(this).parents('.form-item').addClass('error');
                    } else {
                        $(this).parents('.form-item').removeClass('error');
                    }
                }
            }
        });

        if (!$('.form-item.error').length && !$('.checkbox-item.error').length && !$('.form-input[data-focus="false"]').length) {
            $('.connect-form [type="submit"]').removeClass('disactive');
            $('.connect-form [type="submit"]').on("click", function() {
                $('.connect-form').submit();
            });
        } else {
            $('.connect-form [type="submit"]').addClass('disactive');
        }
    });

    $('.filter-stores #local').on('input', function() {
        var value = $(this).val(),
            parent = $(this).parent('.input-wrapper'),
            select_option = parent.find('.select-option');
        if (!parent.hasClass('opened')) {
            parent.find('.select-list').slideDown(500);
            parent.addClass('opened');
        }
        if (value.length > 0) {
            select_option.each(function() {
                var text = $(this).text();
                if (text.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                    $(this).removeClass('hide');
                } else {
                    $(this).addClass('hide');
                }
            });
        } else {
            select_option.each(function() {
                $(this).removeClass('hide');
            });
        }
    });
    
    $('.filter-stores #local').on('focus', function() {
        $(this).val('');
    });
    
    $('.filter-stores #local').on('blur', function() {
        var this_elem = $(this);
        $(this).val($(this).data('value'));
        setTimeout(function() {
            this_elem.parent().find('.select-option').each(function() {
                $(this).removeClass('hide');
            });
        }, 1000);
    });

    $('.signup-form #country').on('input', function() {
        var value = $(this).val(),
            parent = $(this).parent('.input-wrapper'),
            select_option = parent.find('.select-option');
        if (!parent.hasClass('opened')) {
            parent.find('.select-list').slideDown(500);
            parent.addClass('opened');
        }
        if (value.length > 0) {
            select_option.each(function() {
                var text = $(this).text();
                if (text.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                    $(this).removeClass('hide');
                } else {
                    $(this).addClass('hide');
                }
            });
        } else {
            select_option.each(function() {
                $(this).removeClass('hide');
            });
        }
    });

    // ======= Show password ======= //
    $('.show-password').on("click", function() {
        var parent = $(this).parent(),
            input = parent.children('.form-input-password')
            input_type = input.attr('type');
            
        if (input_type === 'password') {
            input.attr('type', 'text');
            $(this).addClass('hide');
        } else if (input_type === 'text') {
            input.attr('type', 'password');
            $(this).removeClass('hide');
        }
    });

    const swiper = new Swiper('.write-slider .swiper-container', {
        speed: 400,
        navigation: {
            nextEl: '.write-slider .swiper-button-next',
            prevEl: '.write-slider .swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
            },
            // when window width is >= 480px
            768: {
              slidesPerView: 3,
            },
            // when window width is >= 640px
            1061: {
              slidesPerView: 4,
            }
        }
    });

    // setTimeout(function() {
    //     const stores_slider = new Swiper('.stores-slider .swiper-container', {
    //         speed: 400,
    //         // init: false,
    //         spaceBetween: 20,
    //         watchOverflow: true,
    //         loopAdditionalSlides: 1,
    //         pagination: {
    //             el: '.stores-slider .swiper-pagination',
    //             type: 'bullets',
    //             clickable: true,
    //         },
    //         breakpoints: {
    //             // when window width is >= 320px
    //             320: {
    //             slidesPerView: 1,
    //             loop: true,
    //             },
    //             // when window width is >= 480px
    //             768: {
    //             slidesPerView: 3,
    //             spaceBetween: 15,
    //             loop: true,
    //             },
    //             1061: {
    //             slidesPerView: 4,
    //             loop: true,
    //             },
    //             // when window width is >= 640px
    //             1201: {
    //             slidesPerView: 5,
    //             spaceBetween: 20,
    //             }
    //         }
    //     });
    //     if ($('.store').length) {
    //         $('.store').each(function(i, elem) {
    //             setTimeout(function() {
    //                 elem.style.animationPlayState = 'running';
    //             }, 500 + (50 * i));
    //         });
    //     }
    // }, 1000);

// setTimeout(function() {
// }, 7000);

    // ======= Connection menu toggle ======= //

    (function() {
        if ($(window).width() >= 1060) {
            var connect_menu = $('.connection-menu-list');
            connect_menu.slideUp(0);
            $('.connect-button').on("click", function() {
                
                if ($(this).hasClass('opened')) {
                    connect_menu.slideUp(500).removeClass('opened');
                    $(this).removeClass('opened');
                } else {
                    connect_menu.slideDown(500).addClass('opened');
                    $(this).addClass('opened');
                }
            });
        }
    })();

    $('body').on('click', '.show-popup', function() {
        $('body').addClass('stop-scroll');
        $('.missing-transaction-popup').addClass('show');
    });

    $('body').on('click', '.popup-close-btn', function() {
        $('body').removeClass('stop-scroll');
        $(this).parents('.missing-transaction-popup').removeClass('show');
    });

    (function() {
        var date_elem = $('#date');
        if (date_elem.length) {
            $( "#date" ).datepicker({
                nextText: '',
                prevText: '',
                dayNamesMin: ['Sun','Mon','Tue','Wen','Thu','Fri','Sat'],
                showOtherMonths: true,
            });
        }
    })();
    
    if ($('.typed-text').length) {
        var typed = new Typed('.typed-text', {
            strings: ['for everyone', 'savings', 'freedom', 'hope'],
            typeSpeed: 70,
            backSpeed: 50,
            startDelay: 500,
            backDelay: 2000,
            loop: true,
        });
    }
    
    $('.animate-block').not('.anim-run').each(function(){
        if(checkVisible($(this))){
            $(this).addClass('anim-run')
        }
    });

    $(window).scroll(function() {
        $('.animate-block').not('.anim-run').each(function(){
            if(checkVisible($(this))){
                $(this).addClass('anim-run')
            }
        });
     
    });

    (function() {
        if ($('.store').length) {
            $('.store').each(function(i, elem) {
                setTimeout(function() {
                    elem.style.animationPlayState = 'running';
                }, 500 + (50 * i));
            });
        }
    })();
     
    function checkVisible( elm, ev ) {
        ev = ev || "visible";
        var vpH = $(window).height(), // Viewport Height
            st = $(window).scrollTop(), // Scroll Top
            y = $(elm).offset().top,
            elementHeight = $(elm).height();
        if (ev == "visible") return ((y < (vpH + st)) && (y > (st - elementHeight)));
        if (ev == "above") return ((y < (vpH + st)));
    }

    $('.payout-link-block:not(.pending)').fadeOut(0);

    $('.form-file').on("change", function() {
        var file = $(this)[0].files[0],
            file_name = file.name,
            file_size = file.size,
            parent = $(this).parents('.file-item');
            file_size_text = '',
            file_input_label = parent.find('.form-label');

        if (file_size <= 1000) {
            file_size_text = ' (' + file_size + ' B)';
        } else if (file_size > 1000 && file_size <= 1000000) {
            file_size_text = ' (' + (file_size / 1024).toFixed(2) + ' KB)';
        } else {
            file_size_text = ' (' + (file_size / 1048576).toFixed(2) + ' MB)';
        }

        file_input_label.html('<span class="uploading-progress"></span><span class="uploading-text">Uploading file...</span>').removeClass('hover-enable');
        $('.uploading-progress').animate({
            width: '100%',
        }, 1500, 'linear', function() {
            file_input_label.html('<span class="uploaded-text">' + file_name + file_size_text + '</span><span class="uploaded-delete-btn"></span>').removeAttr('for');
        });
    });



    $('.file-item .form-label').on('click', '.uploaded-delete-btn', function() {
        var parent = $(this).parents('.file-item'),
            label = parent.find('.form-label'),
            input = parent.find('.form-file'),
            input_id = input.attr('id');
        
        input.val('');
        label.html('Upload file <span class="text-desktop">(proof of order made)</span>');
        setTimeout(function() {
            label.attr('for', input_id).addClass('hover-enable');
        }, 1000);
    });

    (function() {

        $('.accordeon-item').each(function() {
            $(this).children('.accordeon-content').slideUp(0);
        });

        $('.accordeon-title').on("click", function() {
            var parent = $(this).parent()
                content_block = parent.children('.accordeon-content');
            
            if (parent.hasClass('opened')) {
                parent.removeClass('opened');
                content_block.slideUp(500);
            } else {
                $('.accordeon-item.opened .accordeon-content').slideUp(500);
                $('.accordeon-item.opened').removeClass('opened');
                parent.addClass('opened');
                content_block.slideDown(500);
            }
        });

        if ($('.site-main').hasClass('faq-main')) {
            var href_arr = window.location.href.split('#');
            if (href_arr.length > 1) {
                var elem = $('#' + href_arr[1]);
                if (elem) {
                    elem.addClass('opened');
                    elem.children('.accordeon-content').slideDown(500);
                }
            }
        }

    })();
    (function() {
        if ($('.site-main').hasClass('login-main')) {
            var input_flag = true;
            $('.form-input[required]').each(function() {
                var val = $(this).val();
                if (val == '') {
                    input_flag = false;
                }
            });
            if (input_flag) {
                $('.connect-form [type="submit"]').removeClass('disactive');
            }
        }
    })();

});

function copyToClipboard(copyText: string): void {
    // Create a "hidden" input
    var aux = document.createElement("input");

    // Assign it the value of the specified element
    aux.setAttribute("value", copyText);

    // Append it to the body
    document.body.appendChild(aux);

    // Highlight its content
    aux.select();

    // Copy the highlighted text
    document.execCommand("copy");

    // Remove it from the body
    document.body.removeChild(aux);

    // show alert
    $('.badge').fadeIn(1000);
    $('.badge').fadeOut(3000);

}

function historyAnimRun(): void {
    if ($('.history-table .table-row').length) {
        $('.history-table .table-row').each(function(i, elem) {
            setTimeout(function() {
                elem.style.animationPlayState = 'running';
            }, 500 + (100 * i));
        });
    }
}